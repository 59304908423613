.mrunwrap{
	display:  flex;
	flex-direction: column;
	padding: 5px;
	align-items: flex-end;
	width: 100%;
}

.mythicwrapper{
	display: flex;
	flex-direction: column;
	height: auto;
	background-color: black;
	margin-bottom: 5px;
	padding: 5px;
}

.dungeonbasic{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.mythiciconwrap{
	display: flex;
	flex-direction: row;
}

.playercard{
	display: flex;
}

.rundetails{
	display: flex;
	align-self: center;
	justify-self: center;
}

.headwrap{
display: flex;
justify-content: space-between;
}
