

.bracketwrapper{
	background-color: black;
	padding: 10px;
	height: 100%;
}

.bracket{
	display: flex;
	justify-content: space-between;
}

.match-stats{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
