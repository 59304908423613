.raidwrapper{
	background-color: black;
	height: 100%;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.raidname{
	border-bottom: solid 1px gray;
	width: 100%;
	text-align: center;
}

.difficultywrapper{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-evenly;
}

.difficulty{
	background-color: black;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 80%;
	width: 80%;
}
