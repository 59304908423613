.characterWrap{
	display: flex;
	background-color: #4F4E56;
	flex-direction: column;
	height: auto;
	width: 100%;
	padding: 10px;
	border-radius: 1em;
}

.content{
	display: flex;
	flex-direction: column;
	width: 100%;
}

p{
	margin: 0;
}

.playercard{
	border-bottom: 1px solid black;
}

.namespace{
	display: flex;
	margin: 5px;
	flex-direction: column;
}
.balls{
	display: flex;
}

.raid{
	width: 50%;
}

.mythicplus{
	width: 50%;
}

.arena{
	width: 50%;
}
